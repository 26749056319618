import React from "react"
import "../global.css"
import LandingPage from "../subpages/LandingPage"
import System from "../subpages/System"
import End from "../subpages/End"
import Footer from "../components/Footer"

function Index() {
  return (
    <div>
      <LandingPage />
      <System />
      <End />
      <Footer />
    </div>
  )
}

export default Index
