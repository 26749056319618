import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function LandingPage() {
  const { pageDataYaml } = useStaticQuery(graphql`
    {
      pageDataYaml {
        welcomeText
        landingText
      }
    }
  `)

  const { welcomeText, landingText } = pageDataYaml

  const pageStyle = {
    backgroundImage:
      "url(https://s3.us-east-2.amazonaws.com/digitraker.com/landing/images/shutterstock_77325445-4000-1.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backgroundBlendMode: "multiply",
  }

  const welcomeStyle = {
    letterSpacing: "0.25em",
  }

  return (
    <section id="home" style={pageStyle} className="container-fluid">
      <div className="container">
        <div className="centerItems">
          <h6 style={welcomeStyle}>{welcomeText}</h6>
          <h1>{landingText}</h1>
          <a
            href="https://app.digitraker.com/"
            style={{
              display: "flex",
              justifyContent: "center",
              textDecoration: "none",
              width: "200px",
              padding: "10px",
              border: "none",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              background: "#FFA800",
              borderRadius: "30px",
              textTransform: "uppercase",
              color: "white",
              fontWeight: "600",
              marginTop: "20px",
            }}
          >
            Go to Platform
          </a>
        </div>
      </div>
    </section>
  )
}

export default LandingPage
