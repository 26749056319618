import React from "react"
import Fade from "react-reveal/Fade"

function System() {
  return (
    <div
      id="system"
      className="container-fluid"
      style={{ backgroundColor: "#FFCD6C" }}
    >
      <div className="container">
        <div className="flexbox">
          <h1 className="text-center my-5">The System </h1>
          <div className="row">
            <Fade bottom>
              <div className="col-lg-3 my-4 col-sm-12">
                <img
                  alt="temperature"
                  src="https://s3.us-east-2.amazonaws.com/digitraker.com/landing/images/temperature.webp"
                  width="128px"
                />
                <h3 className="mt-5">Measurement</h3>
                <p>
                  DigiTraker devices continuously measure the temperature,
                  humidity or pressure, ensuring it is within range. Sensors can
                  be added over time as you develop.
                </p>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-lg-3 my-4 col-sm-12">
                <img
                  alt="battery"
                  src="https://s3.us-east-2.amazonaws.com/digitraker.com/landing/images/battery.png"
                  width="128px"
                />
                <h3 className="mt-5">Battery</h3>
                <p>
                  DigiTraker sensors are powered by Lithium Ion Batteries. The
                  combination of short on-air time and sophisticated power
                  management allows extended battery life.
                </p>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-lg-3 my-4 col-sm-12">
                <img
                  alt="router"
                  src="https://s3.us-east-2.amazonaws.com/digitraker.com/landing/images/router.png"
                  width="128px"
                />
                <h3 className="mt-5">Repeaters</h3>
                <p>
                  DigiTraker uses smart Intelligent Repeaters, which provide the
                  backbone of the network and can accommodate virtually any size
                  application.
                </p>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-lg-3 my-4 col-sm-12">
                <img
                  alt="web"
                  src="https://s3.us-east-2.amazonaws.com/digitraker.com/landing/images/web.png"
                  width="128px"
                />
                <h3 className="mt-5">Web Platform</h3>
                <p>
                  DigiTraker has a modern web platform, that allows to access your
                  data in real time and manage all your installed sensors.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default System
