import React from "react"

function Footer() {
  return (
    <footer
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        margin: "0px",
        padding: "12px",
        backgroundColor: "#5E35B1",
      }}
    >
      Digitraker | © Copyright 2020
    </footer>
  )
}

export default Footer
